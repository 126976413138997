import ApiHelper from '@/helpers/ApiHelper'
import { JOB_SERVER, JOB_SERVER_API_KEY } from '@/servers'
import localForage from 'localforage'

localForage.config({
    driver: localForage.INDEXEDDB,
    name: `${process.env.VUE_APP_CODENAME}_app_storage`
});

export default {
    namespaced: true,
    state: {
        currentYear: null,
        subjects: null,
        wysiwygEditorKey: null
    },
    mutations: {
        SET_VALUE (state, payload) {
            if (!Array.isArray(payload)) { throw new Error('"payload" argument must be an Array') }
            if (payload.length !== 2) { throw new Error('"payload" argument must have exactly 2 elements') }
            state[payload[0]] = payload[1]
        }
    },
    actions: {
        async initWisiwygEditorKey ({ dispatch }) {
            
            const key = await localForage.getItem('wysiwyg_editor_key')
            dispatch('setWysiwygEditorKey', key)
        },
        async setWysiwygEditorKey ({ commit }, key) {
            
            await localForage.setItem('wysiwyg_editor_key', key)
            commit('SET_VALUE', ['wysiwygEditorKey', key])
        },
        switchOptions (context, payload) {
            return ApiHelper.post(`${ JOB_SERVER }/api/site/switch-options`, payload);
        },
        async fetchCurrentYear ({ commit }) {
            const { success, data, error } = await ApiHelper.get(`${ JOB_SERVER }/api/site/current-year`);

            if (!success) {

                console.error(error)
                return false
            }
            commit('SET_VALUE', ['currentYear', data.year])
        },
        async fetchSubjects ({ commit }) {
            try {
                const { data } = await ApiHelper.get(`${JOB_SERVER}/api/site/subjects`, {}, { api_key: JOB_SERVER_API_KEY })

                if (!data)
                    throw new Error('Subject wasn\'t loaded.')
                
                commit('SET_VALUE', ['subjects', data || []])
            } catch (e) {
                
                console.error(e)
                
                commit('SET_VALUE', ['subjects', []])
            }
        },
        waitUntilRequiredDataLoaded ({ state }) {
            return new Promise(resolve => {
                const intervalId = setInterval(() => {
                    if (Array.isArray(state.subjects)) {
                        clearInterval(intervalId)
                        resolve(true)
                    }
                }, 100)
            })
        }
    }
}