<template>
    <v-progress-circular
        v-if="loading.initial"
        :size="50"
        :color="$const.color.primary"
        indeterminate
        class="d-flex mx-auto"
    ></v-progress-circular>
    <v-form v-else>
        <v-select
            v-model="$v.form.subject.$model"
            :error-messages="getErrors('form.subject')"
            required
            :items="subjects"
            label="Предмет"
        />

        <v-select
            v-model="$v.form.grade.$model"
            :disabled="!$v.form.subject.$model"
            :error-messages="getErrors('form.grade')"
            required
            :items="grades"
            label="Класс"
        />

        <v-select
            v-model="$v.form.category.$model"
            :error-messages="getErrors('form.category')"
            label="Категория"
            :items="categories"
            :color="$const.color.primary" 
        />

        <v-select
            v-if="form.category === 'control'"
            v-model="$v.form.collection.$model"
            :error-messages="getErrors('form.collection')"
            required
            :items="collections"
            label="Коллекция"
            name="collection"
        ></v-select>
        <div>
            <v-btn
                small
                dark 
                outlined 
                :color="$const.color.primary" 
                :loading="loading.downloadThemes"
                @click.prevent.stop="downloadThemes"
            >Скачать список тем</v-btn>
        </div>

        <v-file-input
            v-model="$v.form.taskToThemesFile.$model"
            :error-messages="getErrors('form.taskToThemesFile')"
            show-size
            accept=".csv"
            label="Файл списка заданий к темам (csv)"
        ></v-file-input>
        <a
            :href="`${backendHost}/upload/file_templates/tasks_to_themes.csv`"
            download="tasks_to_themes.csv"
            target="_blank"
            class="caption"
        >Скачать шаблон списка заданий к темам</a>
        
        <template v-if="serverErrors && serverErrors.summary" >
        <v-alert dense type="error">
            {{ serverErrors.summary }}
        </v-alert>
        </template>

        <v-divider class="my-4" />
        <div class="d-flex flex-column">
            <div class="mb-2">
                <v-btn
                    dark
                    :color="$const.color.primary"
                    :loading="waiting"
                    @click.prevent.stop="submit"
                >
                    Загрузить
                </v-btn>
            </div>
            <span class="caption text--grey">Настройки csv файлов: разделитель ячеек - вертикальная черта (pipe) - <code>|</code>. Разделитель текста - двойные кавычки <code>"</code>.</span>
        </div>
       
    </v-form>
</template>

<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { JOB_SERVER } from '@/servers'
import { saveDataAsFile } from '../../helpers/File'

export default {
  props: {
    model: { type: Object }
  },
  mixins: [errorMixin, saveMixin, validationMixin],
  data () {
    return {
        loading: {
            initial: false,
            downloadThemes: false
        },
        dialogShow: false,
        categories: [{value: 'test', text: 'ВПР'}, {value: 'control', text: 'Контрольная работа'}],
        form: {
            subject: null,
            grade: null,
            collection: 1,
            category: 'test',
            taskToThemesFile: null
        },
        waiting: false
    };
  },
  validations() {
    return {
      form: {
        subject: { required },
        grade: { required },
        collection: { required },
        category: { required },
        taskToThemesFile: { required },
      }
    }
  },
  computed: {
    subjects () {
        return this.$store.state.app.subjects
    },
    grades () {
        if (!this.form.subject) { return [] }
        return this.subjects.find(item => item.value === this.form.subject)?.grades.flat()
    },
    backendHost () {
        return JOB_SERVER
    },
    collections () {
        return (new Array(10)).fill(null).map((item, index) => index + 1)
    }
  },
  async created () {
        this.loading.initial = true
        try {
            await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        } catch (e) {
            console.error(e)
        } finally {
            this.loading.initial = false
        }
    },
  methods: {
    async submit () {
      this.dialogShow = false;

      if (!this.validate()) {
        console.error('Validation failed');
        return false;
      }
      this.waiting = true;
      this.serverErrors = null;

      let form = new FormData();
      _.forOwn(this.form, (v,k) => {
        form.append(k, v);
      })

      const { success, error } = await this.$store.dispatch('task/themesUpload', form);

      if(!success) {
        this.serverErrors = error;
      } else {
        alert('Темы успешно загружены')
        this.$emit('back', 1);
      }

      this.waiting = false;
      return;
    },
    async downloadThemes () {

        this.loading.downloadThemes = true
        
        try {

            const themes = await this.findThemes()
            themes.sort((a, b) => a.id - b.id)
            const themesList = themes.map((theme) => `${theme.id}|${theme.name}`)
            const data = ['ID|Название'].concat(themesList).join('\r')

            saveDataAsFile({ data, type: 'text/csv', name: 'ОКО ВПР - Список тем.csv' })
        } catch (e) {

            console.error(e)
        } finally {

            this.loading.downloadThemes = false
        }
    },
    async findThemes () {
            const { success, data, error } = await this.$store.dispatch('theme/list', { fields: 'name' })
        
        if (!success) {
            console.error(error)
            return null
        }

        return data.items
    },
  },
  watch: {
    'form.category' () {
        if (this.form.category === 'test')
            this.form.collection = 1
    }
  }
}
</script>
